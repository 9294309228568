<template>
  <!-- <div class="swiper-red-pagination"></div> -->
  <div :class="classes" />
</template>

<script>
export default {
  name: "SwiperRedPagination",
  props: {
    paginationWhite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "swiper-red-pagination": true,
        "swiper-red-pagination_white": this.paginationWhite,
      };
    },
  },
};
</script>
<style lang="scss">
.swiper-red-pagination {
  display: flex;
  justify-content: center;
  .swiper-pagination-bullet {
    border: 2px solid #dedede;
    border-radius: 0;
    background-color: transparent;
    height: 6px;
    width: 6px;
    transition: 0.3s;
    &:hover {
      border: 2px solid #e14313;
      opacity: 1;
    }
    &-active {
      background: #e63e1f;
      border: 2px solid #e63e1f;
      opacity: 1;
      width: 23px;
      height: 6px;
      transition-duration: 0.5s;
    }
  }
}

.swiper-red-pagination_white {
  display: flex;
  justify-content: center;
  .swiper-pagination-bullet {
    border: 2px solid #dedede;
    border-radius: 0;
    background-color: transparent;
    height: 6px;
    width: 6px;
    transition: 0.3s;
    &:hover {
      border: 2px solid #fff;
      opacity: 1;
    }
    &-active {
      background: #fff;
      border: 2px solid #fff;
      opacity: 1;
      width: 23px;
      height: 6px;
      transition-duration: 0.5s;
    }
  }
}
</style>
